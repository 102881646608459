import React from "react"
import { Link } from "gatsby"

import Logo from "./logo"

import "../styles/navbar.scss"

const Navbar = ({ toggleMenuOpen, menuOpen }) => {
  return (
    <nav>
      <div className="navbar-inner">
        <div className="navbar-logo-wrapper">
          <Link
            to="/"
            className="navbar-logo-link"
            activeStyle={{ fill: "var(--tertiary-color)" }}
          >
            <Logo width="30px" height="30px" />
          </Link>
        </div>
        <button
          className="navbar-menu-wrapper"
          onClick={toggleMenuOpen}
          tabIndex={-1}
          style={
            menuOpen
              ? { color: "var(--background-color)" }
              : { color: "var(--primary-color)" }
          }
        >
          Menu
        </button>
      </div>
    </nav>
  )
}

export default Navbar
