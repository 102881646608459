import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Scrollbar from "smooth-scrollbar"
import EdgeEasingPlugin from "../components/scrollbarEdgePlugin.ts"
import { motion } from "framer-motion"

import Navbar from "./navbar"
import Footer from "./footer"
import Menu from "./menu"

import "../styles/scrollbar.scss"

const SmoothScrollbar = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  function toggleMenuOpen() {
    setMenuOpen(!menuOpen)
  }

  let scrollbar = useRef()

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    Scrollbar.use(EdgeEasingPlugin)
    scrollbar.current = Scrollbar.init(document.querySelector(".container"), {
      renderByPixels: false,
      delegateTo: document.querySelector(".scroll-target"),
      damping: 0.08,
    })

    Scrollbar.detachStyle()

    // Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element:
    ScrollTrigger.scrollerProxy(".content", {
      scrollTop(value) {
        if (arguments.length) {
          scrollbar.current.scrollTop = value // setter
        }
        return scrollbar.current.scrollTop // getter
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: scrollbar.current.getSize().container.width,
          height: scrollbar.current.getSize().container.height,
        }
      },
    })

    // when the smooth scroller updates, tell ScrollTrigger to update() too:
    scrollbar.current.addListener(() => {
      ScrollTrigger.refresh()
      ScrollTrigger.update()
    })

    let currentScroll = scrollbar.current.scrollTop

    const skew = () => {
      let newScroll = scrollbar.current.scrollTop
      let increment = newScroll - currentScroll
      let speed = increment * 0.1
      function speedLimit(value) {
        const limit = 3.5
        if (value < -limit) {
          return -limit
        } else if (value > limit) {
          return limit
        } else {
          return value
        }
      }

      document.querySelector(
        ".content-inner"
      ).style.transform = `skewY(${speedLimit(speed)}deg)`

      currentScroll = newScroll

      requestAnimationFrame(() => skew())
    }
    skew()

    return () => {
      Scrollbar.destroyAll()
    }
  }, [])
  // Footer Parallax Effect
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.from(".footer-inner", {
      y: "-60vh",
      ease: "none",
      scrollTrigger: {
        scrub: true,
        trigger: ".footer-wrapper",
        start: "top bottom",
        end: "top top",
      },
    })
  }, [])

  return (
    <>
      <motion.div
        key="panel-left"
        className="transition-box-left"
        initial={{ scaleX: 1, transformOrigin: "left" }}
        animate={{ scaleX: 0, transformOrigin: "right" }}
        transition={{ duration: 1, ease: [0.83, 0, 0.17, 1] }}
        exit={{ scaleX: 1, transformOrigin: "left" }}
      ></motion.div>
      <Menu menuOpen={menuOpen} />
      <div className="scroll-target">
        <Navbar toggleMenuOpen={toggleMenuOpen} menuOpen={menuOpen} />

        <div className="container">
          <div className="content">
            <div className="content-inner">
              {children}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmoothScrollbar
