import React from "react"

const Pinterest = ({ width, height }) => {
  return (
    <>
      <a
        href="https://pinterest.com/coconutmochaa/"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          className="social-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 416 512"
          height={height}
          width={width}
        >
          <title>Pinterest Logo</title>
          <defs></defs>
          <path
            d="M215,0C74.6,0,0,89.9,0,188c0,45.5,25.4,102.2,66.1,120.2c11.6,5.2,10.1-1.2,20.1-39.3c0.8-3.2,0.4-5.9-2.2-8.9
	C25.8,192.7,72.6,54.4,206.7,54.4c194,0,157.7,268.4,33.7,268.4c-32,0-55.8-25.1-48.2-56.1c9.1-37,27-76.7,27-103.4
	c0-67.2-100.1-57.2-100.1,31.8c0,27.5,9.7,46.1,9.7,46.1s-32.2,130-38.2,154.3c-10.1,41.1,1.4,107.7,2.4,113.5
	c0.6,3.2,4.2,4.2,6.1,1.6c3.2-4.2,42.1-59.7,53-99.8c4-14.6,20.2-73.9,20.2-73.9c10.7,19.4,41.7,35.6,74.6,35.6
	c98,0,168.9-86.2,168.9-193.1C415.6,76.7,327.9,0,215,0L215,0z"
          />
        </svg>
      </a>
    </>
  )
}

export default Pinterest
