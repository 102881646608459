import React from "react"

const Dribbble = ({ width, height }) => {
  return (
    <>
      <a
        href="https://dribbble.com/coconutmocha"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 186.8 186.8"
          overflow="visible"
          className="social-icon"
        >
          <title>Dribbble Logo</title>
          <path
            d="M93.4,0C41.9,0,0,41.9,0,93.4s41.9,93.4,93.4,93.4s93.4-41.9,93.4-93.4S144.9,0,93.4,0z M154.3,44.1
	c10.5,12.9,16.9,29.2,17.5,47c-19.4-4-37-3.6-52.6-0.5c-2.8-6.6-5.7-12.8-8.7-18.8C127.5,64.5,142.2,55.2,154.3,44.1z M143.8,33.4
	c-12,11-25.9,19.1-40.2,25.1C93.2,39.3,83.2,24.9,77.1,16.7c5.3-1.1,10.7-1.7,16.3-1.7C112.6,15,130.1,21.9,143.8,33.4z M61.9,21.6
	c4.6,6,15.6,20.9,27.3,42.1C59.7,73.4,31,75,17.2,75.2C22.9,51.2,39.7,31.4,61.9,21.6z M15.1,90.2c0.1,0,0.3,0,0.4,0
	c9.5,0,27-0.6,47.6-4.4c11.7-2.1,22.9-5,33.3-8.6c2.7,5.4,5.4,11.1,8,17.1c-14.6,4.7-26.9,11.5-36.7,18.2
	c-16.4,11.3-27.8,23.8-34.1,31.5C22,130.4,15,112.7,15,93.4C15,92.3,15,91.3,15.1,90.2z M44.4,154.6c5.4-6.8,16.4-19.1,32.2-30
	c10.8-7.4,21.9-12.8,33.4-16.4c6.7,17.3,12.4,36.4,15.8,56.6c-9.9,4.5-20.9,7-32.4,7C74.9,171.8,57.9,165.4,44.4,154.6z
	 M139.6,156.7c-3.5-18.6-8.7-36.1-14.7-52.1c14.8-2.5,30.2-2,45.9,1.6C167.4,126.8,155.9,144.8,139.6,156.7z"
          />
        </svg>
      </a>
    </>
  )
}

export default Dribbble
