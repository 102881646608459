import React from "react"

const Logo = ({ width, height }) => {
  return (
    <>
      <svg
        version="1.1"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={width}
        height={height}
        viewBox="0 0 40.4 20.7"
        overflow="visible"
      >
        <g>
          <path
            d="M0,18.8l2.1-6.6l0.5,0.1c1.1,3.8,3.4,7.2,6.4,7.2c1.1,0,1.9-0.5,1.9-1.6c0-1.8-1.8-3.3-5-5.8
		C1.8,9,0.6,7.6,0.6,5.4C0.6,1.9,3.9,0,9.6,0c4.3,0,8.6,1.3,9.3,1.9l-2.7,5.5l-0.6-0.1c-1.2-4.8-3.6-6.1-5.4-6.1
		c-0.6,0-1.8,0.1-1.8,1.5c0,1.5,1.9,3.1,5.7,6.1c3.8,3,5.1,4.2,5.1,6.5c0,4.2-4.4,5.6-9.5,5.6C6.3,20.7,1.7,20.1,0,18.8z"
          />
          <path
            d="M40.3,20.3h-3.7l-3.1-3.8c-0.7,2.6-2.6,4.2-5.7,4.2c-3.1,0-5.3-1.5-5.3-6c-0.1-4.2,0-6.5-0.1-9.3
		c2.5,0.1,5,0.1,7.6,0c-0.1,2.8,0,3.9,0.1,8.7c0,3,0.1,4.2,1.1,4.1c1.2-0.1,1.7-1.6,1.7-5.7c0-1.2,0-4.2-0.1-7.1
		c2.6,0.1,5.1,0.1,7.6,0C40.2,10.4,40.2,15.3,40.3,20.3z"
          />
        </g>
      </svg>
    </>
  )
}

export default Logo
