import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link } from "gatsby"

import "../styles/menu.scss"

const Menu = ({ menuOpen }) => {
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {menuOpen && (
          <motion.div
            key="menu"
            className="menu-wrapper"
            transition={{ duration: 0.5, ease: [0.65, 0, 0.35, 1] }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="menu-inner">
              <ul>
                <li className="menu-home-link-wrapper">
                  <Link className="menu-home-link" to="/">
                    <div id="menu-home-link-circle">
                      <span>Home</span>
                    </div>
                  </Link>
                </li>
                <li className="menu-projects-link-wrapper">
                  <Link className="menu-projects-link" to="/projects">
                    <div id="menu-projects-link-circle">
                      <span>Projects</span>
                    </div>
                  </Link>
                </li>
                <li className="menu-about-link-wrapper">
                  <Link className="menu-about-link" to="/about">
                    <div id="menu-about-link-circle">
                      <span>About</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Menu
