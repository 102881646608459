import React from "react"

const Behance = ({ width, height }) => {
  return (
    <>
      <a
        href="https://www.behance.net/sukorkmaz"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 320"
          width={width}
          height={height}
          className="social-icon"
        >
          <title>Behance Logo</title>
          <defs></defs>
          <g>
            <g>
              <g>
                <path
                  d="M210.6,144.6c10.6-5.3,18.7-11.3,24.2-17.7c9.8-11.6,14.6-26.9,14.6-46c0-18.5-4.8-34.4-14.5-47.6
				c-16.1-21.6-43.4-32.7-82-33.3H0v312.1h142.6c16.1,0,30.9-1.4,44.7-4.2c13.8-2.8,25.7-8.1,35.7-15.7c9-6.6,16.4-14.8,22.4-24.5
				c9.4-14.7,14.1-31.3,14.1-49.8c0-17.9-4.1-33.2-12.3-45.7C238.9,159.6,226.8,150.5,210.6,144.6z M63.1,54.2h68.9
				c15.1,0,27.6,1.6,37.4,4.9c11.3,4.7,17,14.3,17,28.9c0,13.1-4.3,22.2-12.9,27.4c-8.6,5.2-19.8,7.7-33.5,7.7H63.1V54.2z
				 M172,252.4c-7.6,3.7-18.3,5.5-32.1,5.5H63.1v-83.2H141c13.6,0.1,24.1,1.9,31.7,5.2c13.4,6.1,20.1,17.2,20.1,33.5
				C192.8,232.7,185.9,245.6,172,252.4z"
                />
                <rect x="327.2" y="14.6" width="135.6" height="38.8" />
                <path
                  d="M509.9,167.9c-2.8-18.1-9-34-18.7-47.7c-10.6-15.6-24-26.9-40.4-34.1c-16.3-7.2-34.6-10.8-55-10.8
				c-34.3,0-62.1,10.7-83.6,32c-21.5,21.3-32.2,52-32.2,92c0,42.7,11.9,73.5,35.7,92.4c23.8,18.9,51.2,28.4,82.4,28.4
				c37.7,0,67.1-11.2,88-33.6c13.4-14.1,21-28.1,22.7-41.7h-62.5c-3.6,6.8-7.8,12-12.6,15.9c-8.7,7-20,10.6-33.9,10.6
				c-13.2,0-24.4-2.9-33.8-8.7c-15.4-9.3-23.5-25.5-24.5-48.7h170.5C512.2,193.8,511.5,178.4,509.9,167.9z M343,173.9
				c2.2-15,7.7-26.9,16.3-35.7c8.6-8.8,20.9-13.2,36.5-13.2c14.4,0,26.5,4.1,36.3,12.4c9.7,8.4,15.2,20.5,16.3,36.5H343z"
                />
              </g>
            </g>
          </g>
        </svg>
      </a>
    </>
  )
}

export default Behance
