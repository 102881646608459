import React from "react"

const Instagram = ({ width, height }) => {
  return (
    <>
      <a
        href="https://www.instagram.com/coconut.mocha/"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          className="social-icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 510.9 511"
          width={width}
          height={height}
        >
          <title>Instagram Logo</title>
          <defs></defs>
          <path
            d="M509.6,150.2c-1.2-27.2-5.6-45.8-11.9-62c-6.5-17.2-16.5-32.5-29.5-45.3c-12.8-13-28.3-23.1-45.2-29.4
	c-16.3-6.3-34.8-10.7-62-11.9C333.6,0.3,324.9,0,255.5,0s-78.1,0.3-105.3,1.5c-27.2,1.2-45.8,5.6-62,11.9
	c-17.2,6.5-32.5,16.5-45.3,29.5c-13,12.8-23.1,28.3-29.4,45.2c-6.3,16.3-10.7,34.8-11.9,62C0.3,177.5,0,186.2,0,255.5
	s0.3,78.1,1.5,105.3c1.2,27.2,5.6,45.8,11.9,62c6.5,17.2,16.6,32.5,29.5,45.3c12.8,13,28.3,23.1,45.2,29.4
	c16.3,6.3,34.8,10.7,62,11.9c27.2,1.2,35.9,1.5,105.3,1.5s78.1-0.3,105.3-1.5c27.2-1.2,45.8-5.6,62-11.9
	c34.3-13.3,61.5-40.4,74.8-74.8c6.3-16.3,10.7-34.8,11.9-62c1.2-27.3,1.5-35.9,1.5-105.3S510.8,177.5,509.6,150.2z M463.6,358.9
	c-1.1,25-5.3,38.4-8.8,47.4c-8.6,22.3-26.3,39.9-48.5,48.5c-9,3.5-22.6,7.7-47.4,8.8c-27,1.2-35,1.5-103.2,1.5s-76.4-0.3-103.2-1.5
	c-25-1.1-38.4-5.3-47.4-8.8c-11.1-4.1-21.2-10.6-29.3-19.1c-8.5-8.3-15-18.3-19.1-29.3c-3.5-9-7.7-22.6-8.8-47.4
	c-1.2-27-1.5-35-1.5-103.2s0.3-76.4,1.5-103.2c1.1-25,5.3-38.4,8.8-47.4C60.7,94,67.2,84,75.8,75.8c8.3-8.5,18.3-15,29.3-19.1
	c9-3.5,22.6-7.7,47.4-8.8c27-1.2,35-1.5,103.2-1.5c68.3,0,76.4,0.3,103.2,1.5c25,1.1,38.4,5.3,47.4,8.8
	c11.1,4.1,21.2,10.6,29.3,19.1c8.5,8.3,15,18.3,19.1,29.4c3.5,9,7.7,22.6,8.8,47.4c1.2,27,1.5,35,1.5,103.2S464.8,331.9,463.6,358.9
	z"
          />
          <path
            d="M255.5,124.3c-72.5,0-131.3,58.8-131.3,131.3s58.8,131.3,131.3,131.3c72.5,0,131.3-58.8,131.3-131.3S328,124.3,255.5,124.3z
	 M255.5,340.7c-47,0-85.2-38.1-85.2-85.2s38.1-85.2,85.2-85.2c47,0,85.2,38.1,85.2,85.2S302.6,340.7,255.5,340.7z"
          />
          <path
            d="M422.7,119.1c0,16.9-13.7,30.6-30.6,30.6c-16.9,0-30.6-13.7-30.6-30.6c0-16.9,13.7-30.6,30.6-30.6
	C408.9,88.4,422.7,102.2,422.7,119.1z"
          />
        </svg>
      </a>
    </>
  )
}

export default Instagram
