import React from "react"
import Scrollbar from "smooth-scrollbar"

import Dribbble from "./socials/dribbble"
import Behance from "./socials/behance"
import Instagram from "./socials/instagram"
import Pinterest from "./socials/pinterest"

import "../styles/footer.scss"

const Footer = () => {
  function scrollToTop() {
    const scrollbar = Scrollbar.get(document.querySelector(".container"))
    scrollbar.scrollTo(0, 0, 2048, {
      easing: function (x) {
        return x < 0.5
          ? 16 * x * x * x * x * x
          : 1 - Math.pow(-2 * x + 2, 5) / 2
      },
    })
  }
  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <h6>Contact</h6>
        <a href="mailto:sukorkmaz13@yahoo.com">sukorkmaz13@yahoo.com</a>
        <p>Istanbul</p>
        <div className="footer-socials-wrapper">
          <Dribbble width="50px" height="50px" />
          <Behance width="50px" height="50px" />
          <Instagram width="50px" height="50px" />
          <Pinterest width="50px" height="50px" />
        </div>
      </div>
      <button className="footer-scroll-button" onClick={scrollToTop}>
        Scroll to top
      </button>
    </footer>
  )
}

export default Footer
